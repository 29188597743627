

export default class LoadJSON {

    constructor (data ) {
        console.log("LoadJSON: ",data);
        fetch(data["url"])
        .then((response) => response.json())
        .then((json) => {
            data["callback"](json);
        });

    }

}

