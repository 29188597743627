//import "../scss/modules/nav.scss";
export default class CreateHeader {
  
    constructor (data ) {

        let headerNav = document.querySelector(".header-nav");

        let headerTopNav = document.createElement("ul");
        let headerNavMain = document.createElement("ul");

        headerNav.appendChild(headerTopNav);
        headerNav.appendChild(headerNavMain);

        headerTopNav.setAttribute("class","nav-top");
        headerNavMain.setAttribute("class","nav-main");

        data["nav-top"].forEach((element) => {
            let eachTopNavItem = document.createElement("li");
            let eachTopNavItemAtag = document.createElement("a");
            eachTopNavItemAtag.innerHTML = element["name"];
            if (element["link"]) eachTopNavItemAtag.setAttribute("href",element["link"]);
            if (element["id"]) eachTopNavItemAtag.setAttribute("data-id",element["id"]);
            if (element["section"]) eachTopNavItemAtag.setAttribute("data-section",element["section"]);
            eachTopNavItemAtag.onclick = function() {
                console.log(this.dataset.section);
                window.open("section-landing.html" + "?section=" + this.dataset.section, "_self" );
                //window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
            }
            eachTopNavItem.appendChild(eachTopNavItemAtag);
            headerTopNav.appendChild(eachTopNavItem);
        });

        data["nav-main"].forEach((element) => {
            let eachMainNavItem = document.createElement("li");
            let eachMainNavItemAtag = document.createElement("a");
            eachMainNavItemAtag.innerHTML = element["name"];
            //eachMainNavItemAtag.setAttribute("href",element["link"]);
            //eachMainNavItemAtag.setAttribute("data-id",element["id"]);
            if (element["link"]) eachMainNavItemAtag.setAttribute("href",element["link"]);
            if (element["id"]) eachMainNavItemAtag.setAttribute("data-id",element["id"]);
            if (element["section"]) eachMainNavItemAtag.setAttribute("data-section",element["section"]);
            eachMainNavItemAtag.onclick = function() {
                console.log(this.dataset.section);
                window.open("section-landing.html" + "?section=" + this.dataset.section, "_self" );
                //window.open(TargetPageURL + "?id=" + articleID + "&type=" + articleType, "_self" );
            }
            eachMainNavItem.appendChild(eachMainNavItemAtag);
            headerNavMain.appendChild(eachMainNavItem);
        });

        let mobileOnly = document.createElement("div");
        headerNav.appendChild(mobileOnly);
        mobileOnly.setAttribute("class","mobile-only");

        let headerLogo = document.createElement("div");
        let headerLogoAtag = document.createElement("a");
        let headerLogoImg = document.createElement("img");
        headerLogoImg.setAttribute("src",data["logo"]["image"]);
        headerLogo.setAttribute("class","logo");
        headerLogoAtag.setAttribute("href",data["logo"]["link"]);
        headerLogoAtag.setAttribute("data-id",data["logo"]["id"]);
        headerLogoAtag.appendChild(headerLogoImg);
        headerLogo.appendChild(headerLogoAtag);
        mobileOnly.appendChild(headerLogo);
       
        let headerHamberMenu = document.createElement("div");
        headerHamberMenu.setAttribute("class","hamburger-menu");
        mobileOnly.appendChild(headerHamberMenu);

        let headerOverlay = document.createElement("div");
        headerOverlay.setAttribute("class","overlay");
        mobileOnly.appendChild(headerOverlay);    
        
        let mobileMenu = document.getElementById("mobile-menu");

        headerHamberMenu.addEventListener("click",function(){
            console.log("open mobile menu");
            mobileMenu.classList.add("show");
        })

    }

   


}