

export default class CreateAnnouncement {

    
    constructor (data ) {

        let Scope = this;
        let container = document.querySelector(data["container"]);
        container.classList.add("show");
        Scope.container = container;

       let createContent = document.createElement("div");
       container.appendChild(createContent);
       createContent.setAttribute("class","content");

       createContent.innerHTML = data["data"];


       let createCloseBtn = document.createElement("div");
       container.appendChild(createCloseBtn);
       createCloseBtn.setAttribute("class","closeBtn");


        createCloseBtn.addEventListener("click",function(){
            container.classList.remove("show");
        })

        return Scope;

        
    }

    reset () {
        console.log("accouncement reset function: ");
        console.log(this);
        this.container.innerHTML = '';
    }


}