
import "../scss/modules/tags.scss";
export default class CreateTags {

    constructor (data ) {


        let container = data["container"]; 

        let createTagsContainer = document.createElement("ul");
        createTagsContainer.setAttribute("class","tags");
        container.appendChild(createTagsContainer);
        
        data["tags"].forEach((tag) => {

            let eachTag = document.createElement("li");
            eachTag.innerHTML = tag["name"];
            eachTag.setAttribute("class","tag-" + tag["color"]);
            createTagsContainer.appendChild(eachTag);
            eachTag.addEventListener("click",function(event){
                console.log(event.target);
            });

        })

    }

}



